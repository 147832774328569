<template>
  <div class="row-actions nowrap has-text-right">
    <span v-for="button in buttons" :key="button.name">
      <span
        :class="[button.class, !!button.label ? 'balloon' : null, button.disabled ? 'disabled' : null]"
        :style="button.style"
        v-click-animation:pulse-red="!button.disabled"
        :data-balloon-blunt="!!button.label"
        :aria-label="button.label"
        :data-balloon-pos="!!button.label ? 'left' : null"
        :data-balloon-break="!!button.label"
        @click.prevent="!button.disabled ? emit(button.onClick, button) : null"
      >
        <font-awesome-icon :icon="button.icon" />
      </span>
    </span>
  </div>
</template>

<script lang="ts">
  import { PropType } from 'vue';

  export default {
    name: 'ProductInput',
    props: {
      buttons: Array as PropType<any[]>,
    },
    data() {
      return {};
    },
    methods: {
      emit(event: string, ...args) {
        // Used for cart dropdown to allow the 'ClickOutside' listener to have time to traverse the ancestry before removal
        requestAnimationFrame(() => {
          this.$emit(event, ...args);
        });
      },
    },
    computed: {},
  };
</script>

<style scoped lang="sass">
  .row-actions
    user-select: none
    &.flex
      display: flex
      flex-direction: row
      gap: 0.25rem
    > span > span
      vertical-align: middle
</style>
