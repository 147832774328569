import { reactive, watch, type Ref } from 'vue';

import { Emittable } from '@/data/productlist/generic.ts';
import { getArticles, getArticlesByArtNr } from '@/data/api/products/requests';
import { getDefaultUnit, getArticleBaseUnitAmount, getPieces } from '@/data/api/products/helpers';

import _ from 'lodash';
import { Article } from '@/types/Article';

export class Articles extends Emittable {
  fetchSlaves: boolean;
  disableTombstone: boolean;
  tombstoneRows: any;
  composable: ({}: { rows: Ref<any[]> }) => any;
  constructor({
    fetchSlaves = false,
    disableTombstone = false,
  }: { fetchSlaves?: boolean; disableTombstone?: boolean } = {}) {
    super();
    const self = this;
    this.fetchSlaves = fetchSlaves;
    this.disableTombstone = disableTombstone;
    this.tombstoneRows = _.debounce(async (vm, rows) => {
      const tombstoned = rows.filter((row) => !(row.article_id in vm.c_articles));
      this.emit(vm, {
        name: '_emit',
        event: {
          name: 'store:tombstoned',
          value: tombstoned,
        },
      });
    }, 1000);
    this.composable = ({ rows }) => {
      const state = reactive({
        c_articles: {} as any,
      });

      watch(
        () => rows.value,
        (rows) => {
          if (self.store('debug', 'debugMode')) console.debug('Re-rendering articles', rows);
          // Important that we clone the rows, as we don't want to modify the original data
          let modifiedRows = _.cloneDeep(rows);
          self.modify(this, modifiedRows);
        },
        { deep: true }
      );

      return state;
    };
    this.mixin = {
      data() {
        return {
          c_articles: {},
        };
      },
      watch: {
        'persistent.rows': {
          deep: true,
          handler(rows) {
            if (self.store('debug', 'debugMode')) console.debug('Re-rendering persistent layer(p_c_rows)', rows);
            self.getArticles(this, rows);
          },
        },
      },
    };
  }

  init() {
    this.on('update', this.update.bind(this));
    this.on('article:add-articles', this.addArticles.bind(this));
    this.on('article:disable-tombstone', () => {
      this.disableTombstone = true;
    });
    this.on('article:enable-tombstone', () => {
      this.disableTombstone = false;
    });
  }

  addArticles(vm, event) {
    const articles = event.value;
    const newArticles = [] as any[];
    for (let i = 0; i < articles.length; i++) {
      const article = articles[i];
      if (!(article.id in vm.c_articles)) {
        vm.c_articles[article.id] = article;
        newArticles.push(article);
      }
    }
  }

  async getArticles(vm, rows) {
    if (!rows) {
      if (this.store('debug', 'debugMode')) console.debug('No rows to get articles for');
      return;
    }

    const existing = Object.values(vm.c_articles as Record<string, Article>).reduce((acc, article) => {
      acc.add(article.id);
      acc.add(article.ArtNr);
      return acc;
    }, new Set());

    const articleIds = Array.from(
      rows.reduce((acc, row) => {
        if (row.article_id && !existing.has(row.article_id)) {
          // Set the id in prices so it doesn't get fetched again while we wait for the price
          vm.c_articles[row.article_id] = null;
          acc.add(row.article_id);
        }
        return acc;
      }, new Set())
    ) as string[];

    const artnrs = Array.from(
      rows.reduce((acc, row) => {
        if (!row.article_id && row.artnr && !existing.has(row.article_id)) {
          acc.add(row.artnr);
        }
        return acc;
      }, new Set())
    ) as string[];

    let articles: Record<string, any> = {};

    articles = artnrs.length ? await getArticlesByArtNr(artnrs) : {};

    // console.debug("New Article ids", ids, ids.length)
    articles = { ...articles, ...(articleIds.length ? await getArticles(articleIds) : {}) };

    // Fetch slave and sibling articles
    if (this.fetchSlaves) this.getSlavesAndSiblings(vm, { ...vm.c_articles, ...articles });

    this.updateArticles(vm, articles);
  }

  async getSlavesAndSiblings(vm, articles) {
    let slaveArticles = {};
    if (!_.isEmpty(articles)) {
      const slaveIds = Array.from(
        Object.values(articles).reduce((acc: Set<any>, article: any) => {
          if (!article) return acc; // If for some reason the article doesn't exist, skip it
          if (article?.slaves?.length > 0) {
            article.slaves
              .map((slave) => slave.id)
              .forEach((id) => {
                if (!(id in vm.c_articles)) acc.add(id);
              });
          }
          if (article?.sibling && !(article.sibling in vm.c_articles)) {
            acc.add(article.sibling);
          }
          return acc;
        }, new Set())
      );
      slaveArticles = slaveIds.length ? await getArticles(slaveIds) : {};
    }

    this.updateArticles(vm, slaveArticles);
  }

  updateArticles(vm, articles) {
    for (const articleId in articles) {
      if (!_.isEqual(vm.c_articles[articleId], articles[articleId])) {
        vm.c_articles[articleId] = articles[articleId];
      }
    }
  }

  update(vm, event) {
    var index = vm.persistent.rows.findIndex((row) => row.id === event.row);
    if (index === -1) return;
    switch (event.key) {
      case 'unit':
        var row = vm.persistent.rows[index];
        this.halfPalletHandler(vm, row, event.value);
        break;
    }
  }

  handleEvent(_vm, _event) {
    // switch (event.name) {
    // }
  }

  modify(vm, rows) {
    /*
    const pieces = (row, article) => {
      const pieces = {
        baseunit: null as string | null,
        m2: null as string | null,
      };
      if (row.unit && row.unit !== article.basicUnitOfMeasure) {
        pieces.baseunit = row?.baseunitAmount?.formatted
          ? `${row.amount == 1 ? 'à' : ''} ${row.baseunitAmount.formatted} ${article.basicUnitOfMeasure}`
          : null;
      }

      let showM2Pieces = false;
      let units = Object.keys(article.unit);
      // BEN-1445
      if (
        article.category !== 'tak' &&
        ['p', 'p2', 'pkt'].includes(row.unit) &&
        article.basicUnitOfMeasure !== 'm2' &&
        article.alternativeUnits.some((u) => u.alternateType == 1 && u.alternate == 'm2')
      ) {
        showM2Pieces = true;
        // BEN-1225
      } else if (['p', 'p2'].includes(row.unit) && ['st', 'p', 'm2'].every((u) => units?.includes(u))) {
        showM2Pieces = true;
        // BEN-1286: Show m2 equiv. pieces for articles only sold as pallets
      } else if (
        article.onlySoldAsPallet &&
        article.basicUnitOfMeasure == 'st' &&
        article.alternativeUnits.some((u) => u.alternateType == 1 && u.alternate == 'm2')
      ) {
        showM2Pieces = true;
      }

      if (showM2Pieces && row?.baseunitAmount)
        pieces.m2 = `(${getM2EquivPieces(article, row.baseunitAmount.value)} m2)`;

      return pieces;
    };
    */

    if (!_.isEmpty(vm.c_articles)) {
      // Merge & process data into articles
      let tombstoned = [] as any[];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];

        if (!row.article_id) {
          // Search through c_articles and attempt to match to row.artnr
          for (let id in vm.c_articles) {
            const article = vm.c_articles[id];
            if (!article) continue;
            if (article.ArtNr === row.artnr) {
              row.article_id = id;
              break;
            }
          }
        }

        const article = vm.c_articles[row.article_id];
        if (!article) {
          console.warn(`Article ${row.article_id}(${row.artnr}) not found`);
          if (row.tombstoned !== '1') tombstoned.push(row);
          // delete rows[i]
          continue;
        }
        row.tombstoned = '0'; // If the article exists, it is not tombstoned

        // Make sure unit exists on the article
        try {
          if (!(row.unit in article.unit)) {
            // console.warn(`Unit ${row.unit} not found on article ${row.article_id}`)
            row.unit = getDefaultUnit(article);
          }
        } catch (e) {
          console.error(e);
          continue;
        }

        row.baseunitAmount = getArticleBaseUnitAmount(article, row);
        row.salesUnit = article.basicUnitOfMeasure;
        //row.pieces = pieces(row, article);
        row.pieces = getPieces(row, article);
        row.article = article;
        row.weight = (row?.article?.weight?.net || 0) * (row?.baseunitAmount.value || 0);
      }

      if (tombstoned.length > 0) {
        this.tombstoneRows(vm, tombstoned);
      }
    }

    return rows.filter((row) => row && row.article);
  }

  halfPalletHandler(vm, row, unit) {
    // Check if the article is an exception
    const article = vm.c_articles[row.article_id];
    if (!article || article?.onlyHalfPallet) return;

    // If the current or previous unit is 'p2', we need to change the rows 'article_id' to the other variant
    if (
      (article.sibling && unit === 'p2' && !article.ArtNr?.endsWith('.5')) ||
      (row.unit === 'p2' && article.ArtNr?.endsWith('.5'))
    ) {
      // console.debug("Changing article to sibling", row.article_id, article.sibling)
      row.article_id = article.sibling;
    }
  }
}
