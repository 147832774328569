import type { ListItem } from '@/types/Productlist';
import type { OrderLine } from '@/types/Checkout';
import type { DeliveryMethod } from '@/data/api/freight/freight.d';

import { articleBlocked1299, convertUnit, roundCurrency } from '@/data/api/products/helpers';

import _ from 'lodash';

export function rowsToOrderlines(rows: ListItem[], freightDelivery?: DeliveryMethod): OrderLine[] {
  return (
    rows?.reduce((acc, row, idx) => {
      let line = {
        itemNumber: row.article.ArtNr,
        lineNumber: row.lineNumber,
        salesUnit: row.unit === 'p2' ? 'p' : row.unit,
        salesUnitQuantity: row.amount,
      } as OrderLine;

      if (!row._meta?.addon) line.lineSuffix = '' + idx + 1;
      if (freightDelivery) {
        for (let delivery of freightDelivery.delivery) {
          if (delivery.orderLines.find((orderLine) => orderLine.orderLineNumber === row.lineNumber)) {
            line.transportId = delivery.jointDeliveryCode;
          }
        }
      }

      if (row.comment?.length) {
        line.preTexts = row.comment.split('\n').map((line, index) => ({
          lineNumber: index + 1,
          text: line,
          typeCode: row?.preDocumentClass ? row.preDocumentClass : 'CO02',
        }));
      }

      if (row.batchnr && !row._meta?.treatment) {
        line.batchNumber = row.batchnr;
      }

      acc.push(line);
      return acc;
    }, [] as OrderLine[]) ?? []
  );
}

export function processProductlistRows(rows: any[], prices: Record<string, any>) {
  if (!rows?.length) return [];

  const blockedArticles = rows.reduce((col, row) => ({ [row.id]: articleBlocked1299(row.article), ...col }), {});

  const getRowPrice = (row, type) => {
    let unit = getRowUnit(row, type);
    let price = row.prices?.price;
    let priceUnit = price?.salesPriceUnitOfMeasure;
    switch (type) {
      case 'net_price':
        priceUnit = row.prices?.salesPriceUnitOfMeasure;
        price = row.prices?.price_net?.netPrice;
        break;
      case 'gross_price':
        price = row.prices?.price_sales?.salesPrice;
        priceUnit = row.prices?.salesPriceUnitOfMeasure;
        break;
      case 'unit_price':
        price = row.prices?.price?.netPrice;
        break;
    }

    if (row._meta?.addon_type == 'delivery' && unit == 'ton' && priceUnit === 'kg') {
      price = roundCurrency(price * 1000, 'ton', 'round');
    }
    return price;
  };

  const getRowUnit = (row, type) => {
    let unit = row.prices?.price?.salesUnit || row.salesUnit || 'st';
    switch (type) {
      case 'gross_price':
      case 'net_price':
      case 'unit_price':
        if (row._meta?.addon_type == 'delivery' && unit == 'kg') {
          unit = 'ton';
        }
    }
    return unit;
  };

  const getRowCurrency = (row, unit, type) => {
    let currency;
    switch (type) {
      case 'gross_price':
      case 'net_price':
      case 'unit_price':
        currency = `${row.prices?.price?.currency ?? 'SEK'}/${convertUnit(unit, {
          short: true,
          lowercase: true,
        })}`;
    }
    return currency ?? null;
  };

  const getRowSubtitle = (row, type) => {
    let subtitle;
    let unit = getRowUnit(row, type);

    switch (type) {
      case 'gross_price':
      case 'net_price':
        if (row._meta?.hide_subtitle) break;
        subtitle = {
          value: getRowPrice(row, type),
          currency: getRowCurrency(row, unit, type),
          parenthesis: true,
        };
    }

    return subtitle ?? null;
  };

  const modifiedRows = _.cloneDeep(rows);
  return modifiedRows.map((row) => {
    row.prices = { ...prices[row.id] };

    _.merge(row.prices, row.override_prices ?? {});

    row.quantity = `${row.amount} ${convertUnit(row.salesUnit || 'st', { short: true, lowercase: true })}`;
    row.currency = getRowCurrency(row, row.unit, 'unit_price');
    row.isBlocked = blockedArticles[row.id];

    row.price_unit = getRowUnit(row, 'unit_price');
    row.price = {
      gross: getRowPrice(row, 'gross_price'),
      net: getRowPrice(row, 'net_price'),
      unit: getRowPrice(row, 'unit_price'),
    };
    row.subtitle = {
      gross: getRowSubtitle(row, 'gross_price'),
      net: getRowSubtitle(row, 'net_price'),
    };

    return row;
  });
}
