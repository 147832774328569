/* eslint-disable no-unused-vars */
import { Emittable } from '@/data/productlist/generic.ts';

import _ from 'lodash';

/* Orderline indexing module
 */
export class LineIndexing extends Emittable {
  constructor() {
    super();
    this.mixin = {};
  }

  modify(_vm, rows) {
    // Merge articles with the same unit selected
    let new_rows = rows.map((row, index) => ({
      lineNumber: '' + (index + 1) * 10,
      ...row,
    }));

    return new_rows;
  }
}
