import { createApp } from 'vue';
import pinia from './stores';
import VueGtag from 'vue-gtag';
import * as Sentry from '@sentry/vue';
import { LiveSession } from '@/tracking';
import PortalVue from 'portal-vue';
import dayjs from '@/helpers/dayjs';

import '/node_modules/flag-icons/css/flag-icons.min.css';
import '/node_modules/pikaday/css/pikaday.css';
import '/node_modules/vue-search-select/dist/VueSearchSelect.css';
import './app.sass';
import initDirectives from './directives';

import App from './App.vue';
import router from './router';
import i18n from './languages/i18n';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    dayjs: typeof dayjs;
    _dayjs: dayjs.Dayjs;
  }
}

const app = createApp(App);

initDirectives(app);

/* Sentry */
if (import.meta.env.VITE_APP_ENV && import.meta.env.VITE_APP_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    autoSessionTracking: false,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 0.1,
    beforeSend(event) {
      const ignoreErrors = [
        'Failed to fetch dynamically imported module',
        'Importing a module script failed',
        'Unable to preload CSS',
      ];

      for (const error of ignoreErrors) {
        if (event.exception?.values?.[0]?.value?.includes(error)) {
          return null;
        }
      }

      return event;
    },
  });
}
/* End Sentry */

/* LiveSession */
const lsOptions = {
  rootHostname: '',
};
if (import.meta.env.NODE_ENV === 'production') {
  lsOptions.rootHostname = '.benders.se';
} else if (
  import.meta.env.NODE_ENV === 'staging' ||
  import.meta.env.NODE_ENV === 'testing' ||
  import.meta.env.NODE_ENV === 'testing2'
) {
  lsOptions.rootHostname = '.benders-k8s.duvadev.se';
}
LiveSession.init('3fcf25c4.6b5c69d4', lsOptions, {
  devMode: import.meta.env.VITE_APP_ENV === 'development',
  scriptURL: 'https://cdn.livesession.io/track.js',
});
LiveSession.newPageView();
/* End LiveSession */

/* Fontawesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretUp,
  faCaretDown,
  faUser,
  faMapMarkerAlt as fasMapMarkerAlt,
  faList,
  faExclamationTriangle,
  faPlus,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faCircle,
  faSync,
  faTerminal,
  faLongArrowLeft,
  faDownload as fasDownload,
  faCheck,
  faEdit as fasEdit,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faBars,
  faSearch,
  faShoppingBag,
  faTimes,
  faCalendar,
  faInfoCircle,
  faAngleUp,
  faAngleDown,
  faMapMarkerAlt,
  faDownload,
  faChevronUp,
  faChevronDown,
  faEdit,
  faPaperclip,
  faPen,
  faFilePdf,
  faFileExcel,
  faEyeSlash,
  faClock,
  faWarehouseFull,
} from '@fortawesome/pro-regular-svg-icons';
import { faTruck } from '@fortawesome/sharp-regular-svg-icons';
import { faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faInstagram,
  faFacebook,
  faYoutube,
  faCaretDown,
  faCaretUp,
  faBars,
  faSearch,
  faUser,
  faMapMarkerAlt,
  fasMapMarkerAlt,
  faShoppingBag,
  faTimes,
  faCalendar,
  faInfoCircle,
  faList,
  faAngleUp,
  faAngleDown,
  faMapMarkerAlt,
  faDownload,
  fasDownload,
  faExclamationTriangle,
  faChevronUp,
  faChevronDown,
  faEdit,
  fasEdit,
  faPaperclip,
  faPlus,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faCircle,
  faSync,
  faTerminal,
  faPen,
  faLongArrowLeft,
  faFilePdf,
  faFileExcel,
  faCheck,
  faEyeSlash,
  faClock,
  faWarehouseFull,
  faTruck
);

app.component('font-awesome-icon', FontAwesomeIcon);
/* Fontawesome End */

app.config.globalProperties.dayjs = dayjs;
app.config.globalProperties._dayjs = dayjs();

app.use(
  VueGtag,
  {
    config: { id: 'UA-24410700-11' },
    includes: [
      { id: 'G-0JL5WRL6K0' }, // Benders Kundportal - GA4
    ],
    appName: 'Benders Kundportal',
    pageTrackerScreenviewEnabled: import.meta.env.VITE_APP_ENV !== 'development',
    enabled: import.meta.env.VITE_APP_ENV !== 'development',
  },
  router
);
app.use(PortalVue);
app.use(pinia);
app.use(router);
app.use(i18n);

app.mount('#app');
