import type { OrderLine } from '@/types/Checkout';
import type {
  OrderM3Response as OrderM3,
  OrderRequest,
  OrderRequestLine,
  FreightResponse,
  FreightResponseFailed,
  PackagingResponse,
  PackagingResponseFailed,
  RowMeasurements,
  OrderFreightData,
} from './freight.d';
import { ListItem } from '@/types/Productlist';
import { rowsToOrderlines } from '@/helpers/checkout';
import { instance, cancel } from '@/data/api/freight/axios';

function mapOrderLines(rows: ListItem[], rowMeasurements?: RowMeasurements): OrderRequestLine[] {
  return rowsToOrderlines(rows).map((item: OrderLine) => {
    if (!item.lineNumber) {
      // Might be a bad way to handle this edge case, but generally it should not happen
      console.warn('lineNumber is missing', item);
      item.lineNumber = '';
    }
    return {
      orderLineNumber: item.lineNumber,
      // orderLineSuffix: '?', // Not required
      itemNumber: item.itemNumber,
      unit: item.salesUnit,
      quantity: item.salesUnitQuantity,
      userDefinedHeight: rowMeasurements?.[item.lineNumber]?.height,
      userDefinedLength: rowMeasurements?.[item.lineNumber]?.length,
      userDefinedWidth: rowMeasurements?.[item.lineNumber]?.width,
      userDefinedWeight: rowMeasurements?.[item.lineNumber]?.weight,
    } as OrderRequestLine;
  });
}

export function getOrderFreight(this: any, data: OrderFreightData): Promise<FreightResponse | FreightResponseFailed> {
  const orderLines = mapOrderLines(data.rows, data.rowMeasurements);
  const request: OrderRequest = {
    referenceNumber: data.referenceNumber,
    orderNumber: data.orderNumber,
    countryCode: data.countryCode,
    postalCode: data.postalCode,
    warehouse: data.warehouse,
    orderLines,
  };

  // Do Request
  const _uid = this?._uid ?? '';
  cancel[`freight${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .post(`freight`, request, {
        signal: cancel[`freight${_uid}`].signal,
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          reject('abort');
        } else if (error.response?.status === 409) {
          // Not an actual error,
          // we just need more information to complete the request
          const data = error.response?.data as FreightResponseFailed;
          resolve(data);
        } else {
          reject(error);
        }
      })
      .then((response) => {
        if (response?.status === 200) {
          const data = response.data as FreightResponse;
          resolve(data);
        } else {
          reject(response);
        }
      });
  });
}

export function getOrderPackaging(
  this: any,
  data: OrderFreightData
): Promise<PackagingResponse | PackagingResponseFailed> {
  const orderLines = mapOrderLines(data.rows);
  const request: OrderRequest = {
    referenceNumber: data.referenceNumber,
    orderNumber: data.orderNumber,
    countryCode: data.countryCode,
    postalCode: data.postalCode,
    warehouse: data.warehouse,
    orderLines,
  };

  // Do Request
  const _uid = this?._uid ?? '';
  cancel[`packaging${_uid}`] = new AbortController();
  return new Promise((resolve, reject) => {
    instance
      .post(`packaging`, request, {
        signal: cancel[`packaging${_uid}`].signal,
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          reject('abort');
        } else {
          reject(error);
        }
      })
      .then((response) => {
        if (response?.status === 200) {
          const data = response.data as PackagingResponse;
          resolve(data);
        } else if (response?.status === 409) {
          const data = response.data as PackagingResponseFailed;
          resolve(data);
        } else {
          reject(response);
        }
      });
  });
}

export function getOrderM3(orderNumber: string): Promise<OrderM3> {
  return new Promise((resolve, reject) => {
    if (!orderNumber) {
      reject('Order number is missing');
      return;
    }

    instance
      .get(`get/${orderNumber}`)
      .catch((error) => {
        reject(error);
      })
      .then((response) => {
        if (response?.status === 200) {
          const data = response.data as OrderM3;
          resolve(data);
        } else {
          reject(response);
        }
      });
  });
}

export function postOrderM3(orderNumber: string, data: OrderM3): Promise<void> {
  // Not implemented
  console.warn('postOrderM3 not implemented', orderNumber, data);
  return Promise.resolve();
}
