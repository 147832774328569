import _ from 'lodash';
import i18n from '@/languages/i18n';

import { formatPrice, roundUnit } from '@/data/api/products/helpers';
import { getDeliveryTitle } from '@/helpers';

import { Emittable } from '@/data/productlist/generic.ts';
import { getPriceV2 as getPrice } from '../api/products/requests';

/* Delivery module
 */
export class Delivery extends Emittable {
  constructor() {
    super();
    const self = this;
    this.mixin = {
      data() {
        return {
          pl_delivery: {
            delivery: null,
            pickupDate: null,
            pickupTime: null,
            deliveryDateType: '',
            deliverySpecification: '',
            emballage: '',
            notification: {
              type: 'phone',
              phone: '',
              email: '',
              preset: '',
            },
            chargeable_weight: null,
            promised_time_freight: null,
            promised_price: null, // Updated by a watcher
          },
        };
      },
      computed: {
        p_c_priceListTable() {
          if (self.store('debug', 'debugMode')) console.debug('computed:p_c_priceListTable');
          return this.p_c_address?.customer?.priceListTable || '';
        },
        p_c_daysUntilDelivery() {
          if (self.store('debug', 'debugMode')) console.debug('computed:p_c_daysUntilDelivery');
          if (!this.p_c_selected_warehouse) return null;

          // Count workdays until selected delivery date
          let days = 0;
          while (
            this.p_c_selected_warehouse?.pickuptimes?.[days]?.date &&
            !this.dayjs(this.p_c_selected_warehouse?.pickuptimes?.[days]?.date).isAfter(
              this.dayjs(this.pl_delivery.pickupDate)
            )
          ) {
            days++;
          }
          return days;
        },
        p_c_showPromisedPrice() {
          if (self.store('debug', 'debugMode')) console.debug('computed:p_c_showPromisedPrice');
          if (!this.p_c_selected_warehouse) return false;

          if (this.pl_delivery.delivery?.data?.deliveryType === 'LOK') {
            // BEN-1563: Check if the next pickupday is before or after 11:00
            if (this.p_c_priceListTable === 'EDS') {
              const isBeforeEleven =
                (this.p_c_selected_warehouse?.pickuptimes?.[0]?.slots?.findIndex((s) => /^11:00.*/.test(s)) ?? -1) !==
                -1;
              if (this.p_c_daysUntilDelivery == 3) return true;
              if (!isBeforeEleven && this.p_c_daysUntilDelivery == 4) return true;
              if (this.p_c_daysUntilDelivery >= 4) return false;
            }
            if (this.p_c_daysUntilDelivery >= 4) return false;
            if (this.p_c_daysUntilDelivery == 3) return true;
          } else if (['LKK', 'LLK', 'K17', 'K24', 'K30'].includes(this.pl_delivery.delivery?.data?.deliveryType)) {
            if (this.p_c_daysUntilDelivery >= 8) return false;
            if (this.p_c_daysUntilDelivery >= 3 && this.p_c_daysUntilDelivery <= 7) return true;
          }

          return false;
        },
        p_c_appendPromisedAddon() {
          if (self.store('debug', 'debugMode')) console.debug('computed:p_c_appendPromisedAddon');
          return this.p_c_showPromisedPrice && this.pl_delivery.deliveryDateType === 'LOV';
        },
        p_c_distributionFee() {
          if (self.store('debug', 'debugMode')) console.debug('computed:p_c_distributionFee');
          if (this.p_c_selected_warehouse?.id !== '19U' || this.pl_delivery.delivery?.data?.deliveryType !== 'HLS')
            // BEN-1569: Add distribution fee for HLS deliveries from warehouse 19U
            return null;

          const rows = [] as any[];

          // If chargeable weight is 0-999,99kg, add 1 st of article 00553
          if (this.pl_delivery.chargeable_weight >= 0 && this.pl_delivery.chargeable_weight < 1000) {
            rows.push({
              _meta: {
                addon: true,
                readonly: true,
                addon_type: 'distribution',
                hide_subtitle: true,
              },
              id: 'distributionFee_00553',
              article: {
                ArtNr: '00553',
                descriptionExt: i18n.global.t('delivery.fee_distribution_00553'),
                price: {
                  price: 0,
                  price_total: 0,
                  salesUnit: 'st',
                },
                line_price: {
                  price: 0,
                  lineAmount: 0,
                },
              },
              amount: 1,
              baseunitAmount: {
                formatted: '1',
                value: 1,
              },
              unit: 'st',
              salesUnit: 'st',
            });
          }

          // if chargeable weight is 1000 or more, add X kg of article 00554
          if (this.pl_delivery.chargeable_weight >= 1000) {
            const weight = Math.round(this.pl_delivery.chargeable_weight);
            rows.push({
              _meta: {
                addon: true,
                readonly: true,
                addon_type: 'distribution',
                hide_subtitle: true,
              },
              id: 'distributionFee_00554',
              article: {
                ArtNr: '00554',
                descriptionExt: i18n.global.t('delivery.fee_distribution_00554'),
                price: {
                  price: 0,
                  price_total: 0,
                  salesUnit: 'kg',
                },
                line_price: {
                  price: 0,
                  lineAmount: 0,
                },
              },
              amount: weight,
              baseunitAmount: {
                formatted: weight,
                value: weight,
              },
              unit: 'kg',
              salesUnit: 'kg',
            });
          }

          return rows;
        },
        p_c_promisedTimeFee() {
          // Get addon from pl_delivery.promised_time_freight
          if (!this.pl_delivery.promised_time_freight || this.pl_delivery.deliveryDateType != 'LBT') return null;

          const addons: object[] = [];
          for (const addon of [
            this.pl_delivery.promised_time_freight?.addonFee01,
            this.pl_delivery.promised_time_freight?.addonFee02,
            this.pl_delivery.promised_time_freight?.addonFee03,
          ]) {
            if (!addon) continue;

            addons.push({
              _meta: {
                addon: true,
                readonly: true,
                addon_type: 'promised_time',
                hide_subtitle: true,
              },
              id: 'promisedTimeFee',
              article: {
                ArtNr: addon.freightArticle,
                descriptionExt: getDeliveryTitle(addon),
                price: {
                  price: addon.price,
                  price_total: addon.price,
                  salesUnit: addon.unit,
                },
                line_price: {
                  price: addon.price,
                  lineAmount: addon.price,
                },
              },
              amount: addon.quantity,
              baseunitAmount: {
                formatted: addon.quantity,
                value: addon.quantity,
              },
              unit: addon.unit,
              salesUnit: addon.unit,
            });
          }

          return addons;
        },
      },
      methods: {
        beltFees(rows) {
          // Return early if no articles
          if (!rows) return null;
          // // If warehouse is not 3BE or 3ST, return early
          if (!['3BE', '3ST'].includes(this.p_c_selected_warehouse?.id)) return null;
          // If no article has itemType BE or LT, return early
          if (!rows.find((r) => ['BE', 'LT'].includes(r.article?.itemType))) return null;

          const amount = rows.reduce((acc, r) => {
            if (['BE', 'LT'].includes(r.article?.itemType)) acc += r.baseunitAmount?.value || 0;
            return acc;
          }, 0);

          // Add BE/LT fee
          return {
            _meta: {
              addon: true,
              readonly: true,
              addon_type: 'be/lt',
              hide_subtitle: true,
            },
            id: 'be/lt_fee',
            article: {
              ArtNr: this.p_c_selected_warehouse.id === '3BE' ? '00480' : '00470',
              descriptionExt:
                this.p_c_selected_warehouse.id === '3BE'
                  ? i18n.global.t('delivery.fee_belt_00480')
                  : i18n.global.t('delivery.fee_belt_00470'),
              price: {
                price: 0,
                price_total: 0,
                salesUnit: 'st',
              },
              line_price: {
                price: 0,
                lineAmount: 0,
              },
            },
            amount: amount,
            baseunitAmount: {
              formatted: '1',
              value: 1,
            },
            unit: 'st',
            salesUnit: 'st',
          };
        },
        async updatePromisedPrice() {
          if (self.store('debug', 'debugMode')) console.debug('computed:update_promised_price');
          if (!this.p_c_customer_number) return null;
          this.pl_delivery.promised_price = await getPrice({
            artnr: '00255',
            address: this.p_c_address_id,
            customerNumber: this.p_c_customer_number,
            warehouse: this.p_c_selected_warehouse?.id,
            uncancellable: true,
          })
            .then((price) => {
              return formatPrice(price, 'netPrice', 1, { asObject: true });
            })
            .catch((err) => {
              console.error(err);
              return null;
            });
        },
      },
      watch: {
        p_c_customer_number: {
          immediate: true,
          handler() {
            (this as any).updatePromisedPrice();
          },
        },
        p_c_address_id() {
          (this as any).updatePromisedPrice();
        },
      },
    };
  }

  init() {}

  modify(vm, rows) {
    if (!vm.pl_delivery.delivery) return rows;
    const addedRows = [] as any[];

    // BE/LT Fees
    // const beltFee = vm.beltFees(rows);
    // if (beltFee) addedRows.push(beltFee);

    // Delivery fees
    let deliveryFees = [vm.pl_delivery.delivery.data];
    const addonFees = [
      vm.pl_delivery.delivery?.data?.addonFee01,
      vm.pl_delivery.delivery?.data?.addonFee02,
      vm.pl_delivery.delivery?.data?.addonFee03,
    ].filter(Boolean);
    deliveryFees.push(...addonFees);

    addedRows.push(
      ...deliveryFees.reduce((acc, d) => {
        if (!addonFees.includes(d) && (!d?.deliveryType || !d?.freightArticle)) return acc;

        acc.push({
          _meta: {
            addon: true,
            readonly: true,
            addon_type: d.deliveryType ? 'delivery' : 'delivery_addon',
            hide_subtitle: d.deliveryType ? false : true, // If it has delivery type it's the main delivery fee and we want to show the subtitle
          },
          id: `${d.deliveryType}-${d.freightArticle}`,
          article: {
            ArtNr: d.freightArticle,
            descriptionExt: getDeliveryTitle(d),
            numberOfDecimalPlaces: 1,
            // price: {
            //   price: !['', 'st'].includes(d.unit) ? roundCurrency((d.price / d.quantity) * 1000, 'ton', 'round') : d.price,
            //   price_total: d.price,
            //   salesUnit: !['', 'st'].includes(d.unit) ? 'ton' : 'st',
            // },
            // line_price: {
            //   price: d.price,
            //   lineAmount: d.price,
            // },
          },
          amount: d.quantity,
          baseunitAmount: {
            formatted: roundUnit(d.quantity, 0),
            value: roundUnit(d.quantity, 0, false),
          },
          unit: d.unit,
          salesUnit: d.unit,
        });
        return acc;
      }, [])
    );

    // Append promised delivery date fee
    if (vm.p_c_appendPromisedAddon) {
      addedRows.push({
        _meta: {
          addon: true,
          readonly: true,
          addon_type: 'delivery',
          hide_subtitle: true,
        },
        id: 'deliveryDate',
        article: {
          ArtNr: '00255',
          descriptionExt: i18n.global.t('delivery.fee_promised_date'),
          price: {
            price: vm.pl_delivery.promised_price?.netPrice,
            price_total: vm.pl_delivery.promised_price?.netPrice,
            salesUnit: vm.pl_delivery.promised_price?.salesPriceUnitOfMeasure,
          },
          line_price: {
            price: vm.pl_delivery.promised_price?.netPrice,
            lineAmount: vm.pl_delivery.promised_price?.netPrice,
          },
        },
        amount: 1,
        baseunitAmount: {
          formatted: '1',
          value: 1,
        },
        unit: 'st',
        salesUnit: 'st',
      });
    }

    // Append promised time fee
    if (vm.p_c_promisedTimeFee?.length) {
      addedRows.push(...vm.p_c_promisedTimeFee);
    }

    // Distribution fees
    if (vm.p_c_distributionFee?.length) {
      addedRows.push(...vm.p_c_distributionFee);
    }

    // Iterate new rows and set warehouse to that of the first article (Is required to fetch price)
    if (rows.length > 0)
      for (let r of addedRows) {
        r.warehouse = rows[0].warehouse;
      }

    // Add rows after filtering out any null values
    if (addedRows?.length) rows.push(...addedRows.filter(Boolean));

    return rows;
  }
}
