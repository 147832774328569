<template>
  <form class="search-form" method="GET" action="/" @submit.prevent="() => {}">
    <label for="search_field" class="is-sr-only">Sök</label>
    <div class="field">
      <div class="control">
        <input
          :value="query"
          @input="doSearch"
          name="s"
          id="search_field"
          class="input search-form__input"
          :placeholder="$t('search.article_search_placeholder')"
          type="text"
        />
        <button :aria-label="$t('generic.search')" type="submit" class="icon search-form__button">
          <font-awesome-icon size="lg" :icon="['far', 'search']" />
        </button>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
  import { useHistoryStore } from '@/stores/history';
  import { mapState } from 'pinia';
  import { PropType } from 'vue';

  export default {
    name: 'SearchInputBox',
    props: {
      query: {
        type: String as PropType<string>,
        default: '',
      },
      navigateOnEmpty: {
        type: Boolean as PropType<boolean>,
        default: true,
      },
    },
    computed: {
      ...mapState(useHistoryStore, ['previousPath']),
    },
    methods: {
      doSearch(newQuery: string | Event) {
        if (typeof newQuery === 'object') {
          newQuery = (newQuery.target as HTMLInputElement).value;
        }

        if (this.navigateOnEmpty && newQuery === '') {
          this.$router.push(this.previousPath || '/mina-sidor');
          return;
        }

        if (this.$route.path === '/search') {
          this.$router.replace({ query: { q: newQuery } });
        } else {
          this.$router.push({ path: '/search', query: { q: newQuery } });
        }
      },
    },
  };
</script>
