import axios from 'axios';
import Cookie from 'js-cookie';
import i18n from '@/languages/i18n';
import _ from 'lodash';

import { useNotificationStore } from '@/stores/notification';
import { useAccountStore } from '@/stores/account';
import { useDebugStore } from '@/stores/debug';

const StoredPromises = {};
const cancel = {};

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_PRODUCT_API_BASE_URL,
});

const triggerProductConnectionError = _.debounce(() => {
  if (
    window.sessionStorage.getItem('product_error_status') === 'pending' &&
    +(window.sessionStorage.getItem('product_error_count') ?? 0) >= 4
  ) {
    window.sessionStorage.setItem('product_error_status', 'active');
    const { clearNotification, addNotification } = useNotificationStore();
    clearNotification();
    addNotification({
      message: i18n.global.t('errors.products.connection_problems'),
      source: `productAPI-error`,
      timeout: 0,
    });
  }
}, 5 * 1000);

// Intercept request and refresh Auth header
instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${Cookie.get('benders_logged_in')}`;
  if (!config.params) config.params = {};
  if (!config.params.f) {
    const { filter } = useAccountStore();
    config.params.f = encodeURI(JSON.stringify(filter));
  }
  if (!config.params.region) {
    const { region } = useAccountStore();
    config.params.region = region;
  }
  if (!config.params.locale) {
    const { locale } = useAccountStore();
    config.params.locale = locale;
  }

  const { debugMode } = useDebugStore();
  if (debugMode) {
    config.params.debug = true;
  }

  return config;
});

// Intercept response and check for a notification
instance.interceptors.response.use(
  (response) => {
    const errorStatus = window.sessionStorage.getItem('product_error_status');
    if (errorStatus === 'pending' || errorStatus === 'active') {
      window.sessionStorage.removeItem('product_error_status');
      window.sessionStorage.removeItem('product_error_count');
    }
    if (errorStatus === 'active') {
      const { clearNotification, addNotification } = useNotificationStore();
      clearNotification();
      addNotification({
        message: i18n.global.t('errors.products.connection_restored'),
        source: `productAPI-error`,
      });
    }

    if (response.data && response.data.data && response.data.data.notification) {
      const { addNotification } = useNotificationStore();
      addNotification({
        message: response.data.data.notification,
        source: `productAPI-${response.config.url}`,
        timeout: 8,
      });
    }

    return response;
  },
  (error) => {
    const ignoredStatus = [401, 403, 404];
    if (!error.__CANCEL__ && (!error.response || !ignoredStatus.includes(error.response.status))) {
      window.sessionStorage.setItem('product_error_status', 'pending');
      window.sessionStorage.setItem(
        'product_error_count',
        '' + (window.sessionStorage.getItem('product_error_count') ?? 0) + 1
      );
      triggerProductConnectionError();
    }
    return Promise.reject(error);
  }
);

export { instance, StoredPromises, cancel };
